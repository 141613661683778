import * as React from 'react';
import { FooterComponent as FooterSection } from '@codeparva/gatsby-theme-layout-components';
import { footerConfig as footerData } from '@codeparva/gatsby-theme-tfw-1/src/components/Footer/config';
import { FacilityContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/facilityContext';
import { LayoutContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/layoutContext';
import { LocationsContext } from '@codeparva/gatsby-theme-tfw-1/src/contexts/locationsContext';
import { transformedFooterConfig } from '@codeparva/gatsby-theme-tfw-1/src/components/Footer/transformer';
import { useLocation } from '@reach/router';

export const Footer = () => {
  const facilityDetails = React.useContext(FacilityContext);
  const locationDetails = React.useContext(LocationsContext);
  const layoutDetails = React.useContext(LayoutContext);
  const { footerConfig, topbarConfig } = layoutDetails;
  const location = useLocation();
  const currLocation = location.pathname.split('/')[2];
  let locationDetailsCopy = Object.values(locationDetails);

  if (currLocation && locationDetailsCopy.length > 1) {
    locationDetailsCopy = locationDetailsCopy.filter((location: any) => {
      return location.attributes.location_city.toLowerCase() === currLocation.toLowerCase();
    });
  }
  const transformedFooterData = transformedFooterConfig(
    footerData,
    footerConfig,
    topbarConfig,
    locationDetailsCopy,
    facilityDetails,
  );
  return (
    <div data-testid="layout-footer">
      <FooterSection {...transformedFooterData} variant="new-footer-1" />
    </div>
  );
};
